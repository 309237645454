interface Realms {
  NA: string;
  EU: string;
  FE: string;
}

export const REALMS: Realms = { NA: "na", EU: "eu", FE: "fe" };

// Reference https://code.amazon.com/packages/PVAccountingCDK/blobs/mainline/--/lib/marketplaces.ts
export const MARKETPLACE_TO_REALM: Record<string, string> = {
  A39IBJ37TRP1C6: REALMS.FE, // AUSTRALIA_PROD
  A1RNPCQ4K8U27I: REALMS.FE, // AUSTRALIA_ DEVO
  AMEN7PMS3EDWL: REALMS.EU, // BELGIUM_PROD
  A1YFOMBPFBRY22: REALMS.EU, // BELGIUM_DEVO
  A2Q3Y263D00KWC: REALMS.NA, // BRAZIL_PROD
  AZXD3QD5B39HD: REALMS.NA, // BRAZIL_DEVO
  A2EUQ1WTGCTBG2: REALMS.NA, // CANADA
  A13V1IB3VIYZZH: REALMS.EU, // FRANCE
  A1PA6795UKMFR9: REALMS.EU, // GERMANY
  A21TJRUUN4KGV: REALMS.EU, // INDIA
  APJ6JRA9NG5V4: REALMS.EU, // ITALY_PROD
  A3HOBANJMCMD83: REALMS.EU, // ITALY_DEVO
  A1VC38T7YXB528: REALMS.FE, // JAPAN
  A1AM78C64UM0Y8: REALMS.NA, // MEXICO_PROD
  A3P3J5A7D2ZVXI: REALMS.NA, // MEXICO_DEVO
  A1805IZSGTT6HS: REALMS.EU, // NETHERLANDS_PROD
  A1M3WC0SJ3A38T: REALMS.EU, // NETHERLANDS_DEVO
  A1C3SOZRARQ6R3: REALMS.EU, // POLAND_PROD
  AKY4K4WKH21YQ: REALMS.EU, // POLAND_DEVO
  A1RKKUPIHCS9HS: REALMS.EU, // SPAIN_PROD
  AJZF8LZ1EJVJN: REALMS.EU, // SPAIN_DEVO
  A3K6Y4MI8GDYMT: REALMS.EU, // ROE_EU_PROD
  A1MJEQWJOUEPEQ: REALMS.EU, // ROE_EU_DEVO
  A2MFUE2XK8ZSSY: REALMS.EU, // ROW_EU_PROD
  A1D7Z662KHSYZY: REALMS.EU, // ROW_EU_DEVO
  A15PK738MTQHSO: REALMS.FE, // ROW_FE_PROD
  A9QSUUOYRWVOG: REALMS.FE, // ROW_FE_DEVO
  ART4WZ8MWBX2Y: REALMS.NA, //  ROW_NA_PROD
  A3U49GLEJ1PS4Y: REALMS.NA, // ROW_NA_DEVO
  A2NODRKZP88ZB9: REALMS.EU, // SWEDEN_PROD
  AT614YYYNOC1S: REALMS.EU, // SWEDEN_DEVO
  A1F83G8C2ARO7P: REALMS.EU, // UK
  ATVPDKIKX0DER: REALMS.NA, // US
};

export const MARKETPLACE_TO_COUNTRY_CODE: Record<string, string> = {
  A39IBJ37TRP1C6: "AU",
  A1RNPCQ4K8U27I: "AU_DEVO",
  AMEN7PMS3EDWL: "BE",
  A1YFOMBPFBRY22: "BE_DEVO",
  A2Q3Y263D00KWC: "BR",
  AZXD3QD5B39HD: "BR_DEVO",
  A2EUQ1WTGCTBG2: "CA",
  A1PA6795UKMFR9: "DE",
  A1RKKUPIHCS9HS: "ES",
  AJZF8LZ1EJVJN: "ES_DEVO",
  A13V1IB3VIYZZH: "FR",
  A1F83G8C2ARO7P: "GB",
  A21TJRUUN4KGV: "IN",
  APJ6JRA9NG5V4: "IT",
  A3HOBANJMCMD83: "IT_DEVO",
  A1VC38T7YXB528: "JP",
  A1AM78C64UM0Y8: "MX",
  A3P3J5A7D2ZVXI: "MX_DEVO",
  A1805IZSGTT6HS: "NL",
  A1M3WC0SJ3A38T: "NL_DEVO",
  A1C3SOZRARQ6R3: "PL",
  AKY4K4WKH21YQ: "PL_DEVO",
  A3K6Y4MI8GDYMT: "ROE_EU_PROD",
  A1MJEQWJOUEPEQ: "ROE_EU_DEVO",
  A2MFUE2XK8ZSSY: "ROW_EU_PROD",
  A1D7Z662KHSYZY: "ROW_EU_DEVO",
  A15PK738MTQHSO: "ROW_FE_PROD",
  A9QSUUOYRWVOG: "ROW_FE_DEVO",
  ART4WZ8MWBX2Y: "ROW_NA_PROD",
  A3U49GLEJ1PS4Y: "ROW_NA_DEVO",
  A2NODRKZP88ZB9: "SE",
  AT614YYYNOC1S: "SE_DEVO",
  ATVPDKIKX0DER: "US",
};

export const getCountryCodeFromMarketplace = (
  marketplaceId: string
): string => {
  return MARKETPLACE_TO_COUNTRY_CODE[marketplaceId] || "";
};
