import {SourceEventsToVersion} from "../types/common";
import {AccountingEvent} from "../types/events";

export const isTvodAccountingEvent = (event: AccountingEvent) => {
    return event.glProductGroup == 318;
}

export const getPaymentMethod = (event: AccountingEvent) => {
    if (event.isEmpOrder) return "EMP";
    const paymentId = Object.keys(event.paymentInstruments || {})[0];
    const payment = event.paymentInstruments?.[paymentId];
    switch (payment?.instrument) {
        case "BankAccountPaymentInstrument":
            return "Direct Debit";
        default:
            return payment?.instrument ?? "AMP";
    }
};

export const getPaymentStatus = (sourceEvents: SourceEventsToVersion | undefined) => {
    if (Object.keys(sourceEvents || {}).includes("OrderSettleSucceeded")) return "success"
    if (Object.keys(sourceEvents || {}).includes("OrderCompleted-ClosedNonPayment")) return "error";
    return "in-progress";
};

export const getPaymentStatusText = (sourceEvents: SourceEventsToVersion | undefined, method: string) => {
    if (Object.keys(sourceEvents || {}).includes("OrderSettleSucceeded")) return `Paid (${method})`;
    if (Object.keys(sourceEvents || {}).includes("OrderCompleted-ClosedNonPayment")) return `Failed (${method})`;
    return `Pending (${method})`;
};

export const getFulfillmentStatus = (event: AccountingEvent) => {
    const events =  Object.keys(event.sourceEventsToVersion || {});
    switch (true) {
        case (!isTvodAccountingEvent(event) && !(events.includes("PeriodStart") || events.includes("Resubscribed"))):
        case events.includes("Cancelled"):
            return "error";
        case (events.includes("PeriodStart") || events.includes("Resubscribed")):
        case (isTvodAccountingEvent(event) && events.includes("OrderCompleted-CompleteSuccess")):
            return "success";
        default:
            return "in-progress";
    }
};

export const getFulfillmentText = (event: AccountingEvent) => {
    const events =  Object.keys(event.sourceEventsToVersion || {});
    switch (true) {
        case (!isTvodAccountingEvent(event) && !(events.includes("PeriodStart") || events.includes("Resubscribed"))):
            return "No Period Start"
        case events.includes("Cancelled"):
            return "Cancelled";
        case (events.includes("PeriodStart") || events.includes("Resubscribed")):
        case (isTvodAccountingEvent(event) && events.includes("OrderCompleted-CompleteSuccess")):
            return "Fulfilled";
        default:
            return "Pending";
    }

};

export const getRefundStatus = (sourceEvents: SourceEventsToVersion | undefined) => {
    if (Object.keys(sourceEvents || {}).includes("RefundSucceeded")) return "success"
    if (Object.keys(sourceEvents || {}).includes("RefundFailed")) return "error";
    return "in-progress";
};

export const getRefundStatusText = (sourceEvents: SourceEventsToVersion | undefined) => {
    if (Object.keys(sourceEvents || {}).includes("RefundSucceeded")) return "Refunded"
    if (Object.keys(sourceEvents || {}).includes("RefundFailed")) return "Refund Failed";
    return "No Refund";
};

export const hasPromotions = (event: AccountingEvent) =>
    event.discountsOnRevenue && Object.keys(event.discountsOnRevenue).length > 0;