import {
    Container,
    KeyValuePairs,
    KeyValuePairsProps,
    SpaceBetween,
    StatusIndicator,
} from "@amzn/awsui-components-react";
import Item = KeyValuePairsProps.Item;
import {AccountingEvent} from "../../types/events";
import {
    getFulfillmentStatus,
    getFulfillmentText,
    hasPromotions,
    getPaymentMethod,
    getPaymentStatus,
    getPaymentStatusText, isTvodAccountingEvent, getRefundStatus, getRefundStatusText,
} from "../../helpers/AccountingEventsUtils";
import {screamingSnakeToTitleCase} from "../../helpers/dbdUtils";

interface AccountingEventsSummarySectionProps {
    event: AccountingEvent;
}

export function AccountingEventsSummarySection({ event }: AccountingEventsSummarySectionProps) {

    const method = getPaymentMethod(event)

    const items: Item[] = [
        {
            label: "Created",
            value: (
                <SpaceBetween direction="horizontal" size="xs">
                    {event.creationDate
                        ? new Date(event.creationDate).toLocaleString()
                        : "Unknown"}
                </SpaceBetween>
            ),
        },
        ...((!isTvodAccountingEvent(event)) ? [{
            label: "Subs ID",
            value: (
                <SpaceBetween direction="horizontal" size="xs">
                    {event.subscriptionId + ":" + event.subscriptionPeriod}
                </SpaceBetween>
            ),
        }] : []),
            {
            label: "Fulfillment Type",
            value: screamingSnakeToTitleCase(
                event.fulfillmentType || "IMMEDIATE"
            ),
        },
        {
            label: "Fulfillment Status",
            value: (
                <StatusIndicator type={getFulfillmentStatus(event)}>
                    {getFulfillmentText(event)} {event.cancellationReason && "- (" + event.cancellationReason + ")"}
                </StatusIndicator>
            )
        },
        {
            label: "Payment Status",
            value: method ? (
                <StatusIndicator type={getPaymentStatus(event.sourceEventsToVersion)}>
                    {getPaymentStatusText(event.sourceEventsToVersion, method)}
                </StatusIndicator>
            ) : (
                <StatusIndicator type="stopped">No Payment Data</StatusIndicator>
            ),
        },
        {
            label: "Promotions",
            value: event ? (
                <StatusIndicator
                    type={hasPromotions(event) ? "success" : "stopped"}
                >
                    {hasPromotions(event)
                        ? "Has Promotions"
                        : "No Promotions"}
                </StatusIndicator>
            ) : (
                <StatusIndicator type="stopped">No Product Data</StatusIndicator>
            ),
        },
        {
            label: "Refund Status",
            value: event ? (
                <StatusIndicator
                    type={getRefundStatus(event.sourceEventsToVersion)}
                >
                    {getRefundStatusText(event.sourceEventsToVersion)}
                </StatusIndicator>
            ) : (
                <StatusIndicator type="stopped">No Product Data</StatusIndicator>
            ),
        },

    ];

    return (
        <Container>
            <KeyValuePairs columns={2} items={items} />
        </Container>
    );
}
