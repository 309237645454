import {
  Box,
  Container,
  Header,
  Link,
  SpaceBetween,
  Tabs,
} from "@amzn/awsui-components-react";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StageContext } from "../contexes/OrderIdContext";
import SearchHistory from "./SearchHistory";
import RecentTransactions from "./RecentTransactions";
import { SavedSearches } from "./SavedSearches";

export default function LandingPage() {
  const stage = useContext(StageContext);
  const navigate = useNavigate();
  const location = useLocation();

  let activeTabId = "searches";
  if (location.pathname === "/find") {
    activeTabId = "transactions";
  }
  if (
    location.pathname === "/saved" &&
    process.env.NODE_ENV === "development"
  ) {
    activeTabId = "folders";
  }

  const handleNavigate = ({ detail }: { detail: { activeTabId: string } }) => {
    let path = "/";
    if (detail.activeTabId === "transactions") {
      path = "/find";
    }
    if (
      detail.activeTabId === "folders" &&
      process.env.NODE_ENV === "development"
    ) {
      path = "/saved";
    }
    if (detail.activeTabId === "searches") {
      path = "/";
    }

    navigate(path);
  };
  return (
    <SpaceBetween size="l">
      <Container>
        <SpaceBetween size="xs">
          <Header variant="h2">
            Lusca - Prime Video&#39;s Accounting Engine{" "}
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href="https://w.amazon.com/bin/view/AmazonVideo/TRACK/Systems/Lusca/"
            >
              (Wiki)
            </Link>
          </Header>
          <Box variant="p">
            Lusca is an accounting engine built by Prime Video FinSys. It
            converts Prime Video transactions into Canonical Business Events,
            and books them into Amazon&apos;s ledger.
          </Box>
        </SpaceBetween>
      </Container>
      <StageContext.Provider value={stage}>
        <Tabs
          activeTabId={activeTabId}
          onChange={handleNavigate}
          tabs={[
            {
              id: "searches",
              label: "Recent Searches",
              content: <SearchHistory />,
            },
            {
              id: "transactions",
              label: "Find Transactions",
              content: <RecentTransactions />,
            },
            // Hidden until fully released
            ...(location.pathname === "/saved" &&
            process.env.NODE_ENV === "development"
              ? [
                  {
                    id: "folders",
                    label: "Saved Searches",
                    content: <SavedSearches />,
                  },
                ]
              : []),
          ]}
        />
      </StageContext.Provider>
    </SpaceBetween>
  );
}
